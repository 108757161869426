<template>
  <section>
    <div class="block-url-img">
      <h4>640×480ピクセル</h4>
      <!-- replace character \' in url define in data -->
      <img :src="urlIMG1.replaceAll('\'', '')" alt="">
      <div class="wrap-copy">
        <input ref="url1"
               :value='"<a href=" + urlInvite + ">"
               + "<img src=" + urlIMG1 + "></a>"' />
        <button @click="copyText('url1', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
    <div class="block-url-img">
      <h4>728×90ピクセル</h4>
      <!-- replace character \' in url define in data -->
      <img :src="urlIMG2.replaceAll('\'', '')" alt="">
      <div class="wrap-copy">
        <input ref="url2"
               :value='"<a href=" + urlInvite + ">"
               + "<img src=" + urlIMG2 + "></a>"' />
        <button @click="copyText('url2', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
    <div class="block-url-img">
      <h4>468×60ピクセル</h4>
      <!-- replace character \' in url define in data -->
      <img :src="urlIMG3.replaceAll('\'', '')" alt="">
      <div class="wrap-copy">
        <input ref="url3"
               :value='"<a href=" + urlInvite + ">"
               + "<img src=" + urlIMG3 + "></a>"' />
        <button @click="copyText('url3', $event)">
          <clipboard-icon size="1.5x"/>
          コピーする
          <span>copied!</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ClipboardIcon } from 'vue-feather-icons'
import CommonMixin from '@/mixins/common.mixin'

export default {
  name: 'Tab3',

  components: {
    ClipboardIcon
  },

  mixins: [CommonMixin],

  data () {
    return {
      urlInvite: `'${window.location.origin + '?invite=' + this.$store.state.auth.profile.ref_code}'`,
      URL_STG: {
        urlIMG1: '\'https://stg.chibijob.com/static/images/library_images/sgV9LH2Dui_20241202190823.png\'', // 640x480
        urlIMG2: '\'https://stg.chibijob.com/static/images/library_images/b3eu80ZYtc_20241202190823.png\'', // 728x90
        urlIMG3: '\'https://stg.chibijob.com/static/images/library_images/wjZLVDDytB_20241202190823.png\'' // 468x60
      },
      URL_PROD: {
        urlIMG1: '\'https://chibijob.com/static/images/library_images/SqBGZH6p3a_20241211105406.png\'',
        urlIMG2: '\'https://chibijob.com/static/images/library_images/xSC5eyyPv9_20241211105406.png\'',
        urlIMG3: '\'https://chibijob.com/static/images/library_images/tQ6iws8kPK_20241211105406.png\''
      }
    }
  },

  computed: {
    urlIMG1 () {
      return process.env.NODE_ENV === 'production' ? this.URL_PROD.urlIMG1 : this.URL_STG.urlIMG1
    },
    urlIMG2 () {
      return process.env.NODE_ENV === 'production' ? this.URL_PROD.urlIMG2 : this.URL_STG.urlIMG2
    },
    urlIMG3 () {
      return process.env.NODE_ENV === 'production' ? this.URL_PROD.urlIMG3 : this.URL_STG.urlIMG3
    }
  }
}
</script>

<style lang="scss" scoped>
.block-url-img {
  padding: 24px;
  border-radius: 6px;
  background: #FAFAFA;
  @media #{$info-phone} {
    padding: 20px 18px 23px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  h4 {
    color: #7C934C;
    @include font-size(20px);
    text-align: center;
    margin-bottom: 24px;
    @media #{$info-phone} {
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 12px;
    }
  }
  img {
    display: block;
    margin:auto auto 24px;
    @media #{$info-phone} {
      margin: auto auto 18px;
    }
  }
}
</style>
